import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
					<path d="M1520 3543 c0 -3 36 -51 80 -108 44 -57 80 -108 80 -114 0 -6 -61
-291 -136 -633 l-136 -622 18 -34 c33 -62 34 -62 447 -62 l373 0 62 288 c35
158 100 458 146 667 l84 380 -70 93 c-38 52 -71 92 -73 90 -3 -2 -63 -274
-136 -603 -72 -330 -133 -602 -134 -604 -5 -5 -265 -4 -270 1 -2 2 56 282 130
623 74 340 135 624 135 632 0 11 -56 13 -300 12 -165 -1 -300 -4 -300 -6z"/>
<path d="M2450 3524 c0 -8 4 -13 9 -10 5 3 12 -5 15 -19 4 -14 13 -25 22 -25
8 0 13 -4 10 -9 -6 -9 18 -34 25 -26 3 2 6 -5 6 -17 2 -22 41 -70 52 -63 12 7
9 -38 -5 -114 -8 -41 -12 -76 -9 -79 3 -3 1 -10 -4 -16 -13 -17 -32 -106 -25
-118 4 -5 2 -13 -3 -17 -8 -5 -64 -238 -69 -286 -1 -11 -4 -27 -7 -35 -4 -8
-16 -61 -27 -117 -11 -56 -23 -109 -27 -119 -5 -12 -3 -15 6 -9 9 5 8 0 -2
-16 -8 -13 -25 -70 -36 -126 -11 -55 -28 -131 -36 -168 -9 -37 -14 -74 -11
-82 3 -8 1 -11 -4 -8 -6 3 -10 -5 -10 -19 0 -13 -3 -31 -6 -40 -5 -14 14 -16
179 -16 151 0 187 3 197 15 7 8 10 21 7 29 -3 8 -2 16 2 18 10 4 53 207 46
219 -4 5 -1 9 5 9 7 0 9 7 6 16 -4 9 -1 23 5 30 6 8 9 23 6 35 -3 12 -3 18 1
14 4 -3 19 49 33 117 18 80 24 127 18 133 -7 7 -8 3 -4 -10 5 -16 5 -17 -3 -5
-6 8 -8 18 -5 23 3 4 -1 16 -9 25 -8 9 -20 29 -27 44 -12 24 -11 30 10 60 13
18 26 35 29 38 16 15 32 41 23 38 -6 -2 -10 1 -9 7 0 5 6 10 13 10 27 0 56 60
79 161 13 57 25 119 27 137 2 18 6 38 10 43 7 13 19 75 21 116 0 14 3 24 6 22
5 -5 49 179 50 209 0 16 -20 17 -290 17 -256 0 -290 -2 -290 -16z m83 -59 c-3
-9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m454 -87 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-354 -41 c3 -8 -1 -14 -8 -14 -7 0 -11
6 -8 14 3 7 6 13 8 13 2 0 5 -6 8 -13z m220 -462 c-3 -9 -8 -14 -10 -11 -3 3
-2 9 2 15 9 16 15 13 8 -4z m-85 -140 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z m-301 -297 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-80 -260 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m0 -80
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-20 -30 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m306 -8 c-7 -28 -13 -25 -13 6 0 14 4
23 9 20 5 -3 7 -15 4 -26z"/>
<path d="M3370 3502 c-13 -20 -33 -50 -44 -66 -13 -19 -15 -27 -5 -21 7 5 2
-4 -13 -20 -16 -15 -29 -32 -29 -36 -1 -5 -2 -13 -3 -18 0 -5 -5 -13 -10 -18
-5 -5 -6 -2 -1 6 5 9 4 12 -3 7 -7 -4 -12 -11 -12 -17 0 -5 -15 -30 -34 -57
-19 -26 -36 -53 -38 -61 -2 -7 -12 -24 -23 -36 -31 -35 -61 -89 -44 -80 8 4 2
-4 -12 -18 -38 -37 -72 -95 -63 -108 4 -7 3 -9 -3 -6 -11 7 -40 -29 -45 -55
-2 -7 -10 -19 -18 -26 -13 -12 -13 -13 0 -7 8 3 5 -2 -7 -11 -19 -14 -21 -19
-11 -38 10 -19 9 -20 -5 -8 -13 11 -19 7 -41 -31 -26 -44 -32 -62 -18 -54 4 3
8 -15 8 -39 1 -24 7 -50 13 -58 6 -7 9 -21 5 -30 -3 -9 -2 -16 2 -16 5 0 10
-15 11 -33 1 -17 13 -83 27 -145 18 -83 23 -120 16 -138 -22 -56 -6 -127 40
-179 10 -11 21 -38 25 -60 4 -22 9 -44 12 -49 4 -5 7 -24 9 -42 2 -18 8 -52
15 -76 7 -24 12 -46 11 -50 -1 -4 14 0 33 9 24 11 34 22 30 31 -5 13 -3 13 11
2 13 -12 17 -11 25 1 5 8 9 11 9 6 0 -4 15 0 33 9 57 29 59 34 27 61 -16 13
-33 22 -38 19 -5 -3 -9 0 -10 7 0 7 -4 32 -8 56 l-7 43 32 -7 c18 -4 69 -25
113 -47 44 -21 82 -37 84 -35 3 2 -31 23 -74 45 -44 23 -85 48 -91 56 -7 8
-15 36 -17 61 -6 55 -13 55 124 0 75 -30 82 -32 82 -15 0 10 -4 21 -9 24 -5 3
-7 14 -4 24 2 9 -1 38 -7 62 -6 25 -12 65 -15 89 -4 47 -12 62 -23 44 -13 -20
-197 -107 -229 -108 -18 -1 -31 0 -29 3 7 6 -66 67 -77 64 -5 -2 -7 4 -4 11 3
8 -1 27 -9 43 -19 36 -13 51 25 59 16 3 35 13 42 21 6 8 19 14 28 14 16 0 209
94 224 109 10 10 -11 161 -24 175 -4 5 -3 19 1 32 5 13 7 30 4 38 -3 8 -1 17
5 21 7 3 8 0 5 -9 -12 -32 5 -15 36 37 18 28 41 64 52 80 10 15 16 33 12 39
-4 7 -3 8 5 4 6 -4 14 0 19 11 4 10 38 64 75 121 37 57 71 111 74 120 4 9 10
19 13 22 14 10 103 155 103 168 0 6 4 12 8 12 4 0 21 23 38 50 l31 50 -208 0
-207 0 -25 -38z m45 18 c-3 -5 2 -10 12 -11 11 0 13 -3 5 -6 -6 -2 -10 -10 -6
-16 3 -7 -1 -4 -9 5 -8 10 -17 15 -20 11 -4 -3 -7 -2 -7 4 0 12 10 23 22 23 5
0 6 -4 3 -10z m297 -52 c-17 -17 -15 -33 3 -18 13 11 14 10 9 -3 -3 -9 -14
-15 -24 -14 -10 1 -16 6 -13 12 11 18 25 35 31 35 3 0 1 -5 -6 -12z m-8 -56
c3 -5 -1 -9 -9 -9 -8 0 -12 4 -9 9 3 4 7 8 9 8 2 0 6 -4 9 -8z m-387 -54 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m276 -142 c-10 -13 -20 -22
-22 -20 -4 4 29 44 36 44 2 0 -4 -11 -14 -24z m-54 -70 c8 -10 8 -16 -1 -24
-10 -8 -13 -4 -13 14 0 28 -1 28 14 10z m-368 -33 c-12 -20 -14 -14 -5 12 4 9
9 14 11 11 3 -2 0 -13 -6 -23z m-30 -14 c-5 -13 -10 -19 -10 -12 -1 15 10 45
15 40 3 -2 0 -15 -5 -28z m260 -186 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3
-2 0 -13 -6 -23z m-366 -25 c4 4 3 -3 -4 -15 -6 -12 -17 -25 -24 -29 -7 -5 -6
0 3 11 11 14 12 19 3 22 -7 3 -13 9 -13 15 0 7 5 7 13 -1 8 -6 17 -7 22 -3z
m-92 -116 c14 -15 14 -19 3 -19 -8 0 -14 5 -13 11 1 6 -4 10 -11 8 -8 -1 -11
2 -7 8 8 13 9 13 28 -8z m182 -932 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19
12 23 12 15 0z"/>
<path d="M3529 2706 c-9 -17 -40 -51 -70 -75 -30 -25 -46 -42 -37 -38 23 8 16
-30 -9 -49 -17 -13 -17 -14 1 -7 13 4 17 3 12 -5 -4 -7 -2 -12 3 -12 6 0 11 3
11 8 0 4 13 14 29 23 43 25 83 99 79 147 l-3 40 -16 -32z m-69 -150 c0 -2 -7
-7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z"/>
<path d="M3570 2647 c0 -25 -40 -84 -90 -135 -32 -32 -40 -46 -31 -52 11 -6
11 -9 0 -13 -10 -4 -9 -6 3 -6 9 -1 20 4 23 10 4 6 14 8 22 5 16 -6 60 39 80
83 15 32 16 91 3 111 -8 13 -10 12 -10 -3z m-89 -164 c-12 -20 -14 -14 -5 12
4 9 9 14 11 11 3 -2 0 -13 -6 -23z"/>
<path d="M3356 2462 c-4 -8 -207 -110 -260 -131 -16 -7 -26 -14 -23 -17 9 -9
307 134 307 148 0 10 -17 10 -24 0z"/>
<path d="M3461 2431 c-17 -13 -19 -18 -7 -18 9 0 23 9 33 19 21 23 5 23 -26
-1z"/>
<path d="M1453 1813 c-20 -4 -23 -11 -23 -52 0 -30 -6 -53 -14 -60 -19 -16
-25 -4 -33 59 -6 49 -15 58 -42 41 -10 -6 -12 -25 -9 -71 4 -50 9 -67 27 -81
33 -27 58 -24 92 10 26 27 29 36 29 95 0 36 -1 65 -2 65 -2 -1 -13 -4 -25 -6z"/>
<path d="M1494 1797 c-3 -8 -4 -47 -2 -88 3 -68 5 -74 25 -77 17 -3 26 4 33
24 l11 27 25 -24 c25 -24 26 -24 40 -5 18 26 18 29 -5 51 -20 17 -20 17 1 46
18 23 19 31 8 44 -18 21 -27 19 -55 -12 l-24 -28 -1 28 c0 22 -4 27 -25 27
-14 0 -28 -6 -31 -13z"/>
<path d="M1682 1731 c-27 -98 -27 -101 3 -101 14 0 25 5 25 11 0 7 16 9 49 4
29 -4 51 -3 55 3 3 6 -6 44 -21 86 -26 73 -28 76 -58 76 -31 0 -32 -2 -53 -79z"/>
<path d="M1860 1792 c-45 -36 -20 -102 38 -102 13 0 21 -4 17 -9 -3 -5 -20 -7
-39 -4 -28 4 -34 2 -39 -16 -6 -26 2 -32 52 -34 49 -2 81 24 81 64 0 30 -23
49 -59 49 -12 0 -21 5 -21 11 0 6 14 9 33 7 26 -2 33 1 35 18 5 36 -61 46 -98
16z"/>
<path d="M2088 1803 c-13 -3 -18 -14 -18 -38 0 -20 -6 -35 -15 -39 -12 -4 -15
3 -15 34 0 41 -9 47 -44 34 -13 -5 -16 -23 -16 -91 l0 -84 28 3 c19 2 28 9 30
26 4 27 32 30 32 2 0 -15 7 -20 25 -20 25 0 25 1 25 90 0 50 -3 89 -7 89 -5
-1 -16 -3 -25 -6z"/>
<path d="M2165 1753 c-9 -32 -19 -73 -22 -90 -5 -30 -3 -33 21 -33 14 0 26 5
26 11 0 7 16 9 49 4 27 -4 51 -3 54 2 2 4 -7 42 -22 83 -24 70 -28 75 -58 78
-30 3 -32 1 -48 -55z m55 -43 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z"/>
<path d="M2338 1803 c-22 -3 -28 -10 -28 -29 0 -19 5 -24 25 -24 24 0 25 -3
25 -60 0 -57 1 -60 25 -60 23 0 24 4 27 63 3 56 5 62 26 65 16 3 22 10 22 28
0 22 -3 24 -47 23 -27 -1 -60 -4 -75 -6z"/>
<path d="M2492 1801 c-9 -5 -12 -20 -9 -40 3 -17 1 -31 -4 -31 -5 0 -9 -7 -9
-15 0 -8 4 -15 9 -15 5 0 7 -13 5 -30 -5 -29 -4 -30 31 -30 49 0 95 44 95 92
0 54 -74 97 -118 69z m103 -33 c5 4 5 1 1 -6 -6 -10 -11 -10 -22 -1 -8 6 -14
14 -14 16 0 3 6 0 13 -6 8 -6 18 -7 22 -3z m-27 -20 c13 -17 -3 -58 -23 -58
-8 0 -16 -8 -16 -17 -1 -17 -2 -17 -6 0 -2 9 -8 15 -12 13 -4 -3 -8 1 -8 9 0
8 4 12 9 9 5 -4 9 0 7 7 -4 33 2 49 20 49 10 0 23 -6 29 -12z m17 -67 c-3 -6
-14 -10 -23 -10 -15 1 -15 2 1 6 9 2 17 9 17 15 0 6 3 9 6 5 3 -3 3 -10 -1
-16z"/>
<path d="M2675 1791 c-3 -10 -3 -27 1 -37 5 -16 4 -16 -5 -4 -9 13 -11 13 -12
0 -1 -16 -4 -32 -15 -67 -5 -17 -2 -23 9 -23 9 0 19 5 22 10 3 6 12 10 18 10
7 0 6 -4 -3 -10 -12 -8 -10 -10 8 -10 13 0 31 -4 41 -9 26 -14 43 1 35 32 -3
15 -9 27 -13 28 -3 0 -14 2 -23 4 -10 2 -18 -3 -18 -11 0 -8 -9 -14 -21 -14
-14 0 -18 4 -12 13 8 12 9 17 12 40 1 9 3 9 9 -1 8 -12 52 -26 52 -17 0 3 -7
23 -16 45 -12 31 -22 40 -39 40 -14 0 -26 -7 -30 -19z"/>
<path d="M2868 1803 c-16 -2 -28 -9 -28 -14 0 -5 -5 -9 -11 -9 -5 0 -7 5 -4
10 3 6 1 10 -4 10 -6 0 -11 -3 -12 -7 0 -5 -2 -16 -4 -25 -4 -21 15 -24 33 -6
14 14 15 9 12 -64 -2 -57 4 -68 30 -61 17 5 20 14 20 63 0 53 2 59 23 62 14 2
23 11 25 26 4 23 -9 26 -80 15z m60 -28 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z"/>
<path d="M3024 1802 c-6 -4 -20 -41 -31 -84 -21 -75 -21 -76 -1 -81 11 -3 21
0 25 9 6 16 44 19 59 4 6 -6 18 -8 28 -4 13 5 16 13 11 33 -4 14 -10 27 -15
29 -5 2 -6 10 -3 19 4 11 2 14 -5 9 -8 -5 -10 -2 -6 9 7 18 -21 65 -39 65 -6
0 -17 -4 -23 -8z m49 -85 c4 3 7 2 7 -4 0 -15 -42 -25 -54 -13 -7 7 -6 10 2
10 7 0 12 8 12 18 1 16 1 16 14 0 7 -10 16 -15 19 -11z"/>
<path d="M3390 1760 c0 -55 5 -63 31 -47 14 8 19 23 19 52 0 37 -2 41 -25 41
-23 0 -25 -4 -25 -46z"/>
<path d="M3500 1801 c0 -6 -9 -8 -20 -6 -19 4 -20 -2 -20 -81 0 -49 4 -84 10
-84 6 0 10 7 10 15 0 8 5 15 11 15 5 0 8 -4 5 -8 -2 -4 9 -9 26 -10 40 -3 32
19 -11 31 -32 8 -32 9 -16 27 20 22 45 16 45 -10 0 -29 25 -44 43 -26 18 19
11 57 -9 49 -8 -3 -11 -10 -7 -17 3 -6 2 -8 -2 -3 -5 4 -10 18 -12 30 -2 15 0
18 6 9 6 -10 10 -8 14 10 13 45 9 56 -18 53 -14 -1 -25 2 -25 6 0 5 -7 9 -15
9 -8 0 -15 -4 -15 -9z m30 -32 c0 -5 5 -7 10 -4 6 3 10 1 10 -4 0 -6 -4 -11
-8 -11 -5 0 -18 -9 -30 -20 -12 -12 -23 -16 -27 -10 -3 6 -1 10 5 10 6 0 8 5
5 10 -7 12 12 40 26 40 5 0 9 -5 9 -11z"/>
<path d="M3172 1785 c-23 -17 -26 -59 -6 -76 8 -7 30 -9 52 -6 20 3 31 3 25 0
-7 -2 -13 -11 -13 -19 0 -8 -10 -14 -26 -14 -18 0 -25 -4 -21 -14 4 -10 0 -12
-15 -8 -14 3 -19 1 -14 -6 11 -19 81 -14 99 6 21 23 17 77 -6 76 -9 -1 -14 2
-11 8 3 5 -5 6 -19 3 -19 -5 -28 -2 -35 12 -9 16 -6 18 33 15 34 -3 43 0 47
15 4 16 -1 19 -32 20 -21 1 -47 -5 -58 -12z"/>
<path d="M3300 1749 c0 -37 3 -48 13 -43 8 5 7 2 -2 -9 -12 -15 -11 -20 3 -36
22 -24 69 -24 95 0 21 20 30 56 11 44 -6 -4 -7 -12 -3 -18 4 -7 -2 -5 -12 3
-14 11 -19 12 -22 2 -3 -6 -10 -9 -15 -6 -6 4 -17 -1 -25 -11 -7 -11 -13 -14
-13 -8 0 7 5 15 10 18 6 3 10 11 10 16 0 6 -3 8 -7 6 -5 -3 -6 17 -5 44 4 44
2 49 -17 49 -19 0 -21 -6 -21 -51z"/>
<path d="M2650 1650 c-25 -6 -25 -6 -3 -12 13 -3 26 -2 29 4 9 14 2 16 -26 8z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
